<template>
  <v-card width="650px" class="mx-auto mt-5">
    <v-container>
      <v-card-title>
        <h1 v-if="user.manage_id.length == 0" class="display-1">予約フォーム</h1>
        <h1 v-else class="display-1">予約変更フォーム</h1>
      </v-card-title>
      
      <v-card-text v-if="user.manage_id.length == 0">予約日時: {{st_tm}}〜</v-card-text>
      <v-card-text v-else>予約変更後日時: {{st_tm}}〜</v-card-text>
      <v-form ref="send_data">
        <div v-for="(category,index) in courses" :key="courses[index].value">
          <v-checkbox light :label="category.label" :value="category.value" v-model="user.course" :rules="[rules.check_least_1]"/>
        </div>
        <v-row>
          <v-col>
              <v-text-field v-model="user.name" label="お名前" outlined :rules="[required]"/>
              <v-text-field v-model="user.phone" label="電話番号（固定電話 or 携帯番号）" outlined :rules="[required, phoneRules.regex]"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
              <v-textarea v-model="user.note" label="要望・その他" outlined />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn large block class="font-weight-bold" v-on:click="$router.back()">戻る</v-btn>
          </v-col>
          <v-col>
            <v-btn color="info" large block class="font-weight-bold" v-on:click="submit">確認</v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
  </v-card>

</template>

<script>
export default {
  props: {
    user: Object
  },
  data() {
    return {
      success: false,
      required: value => !!value || "必ず入力してください",
      requiredselect: value =>  value.length > 0 || "選択してください",
      phoneRules: {
        regex: v => /^[0-9]*$/.test(v) || '数字のみ入力してください'
      },
      rules: {
        check_least_1: value => {
          return value.length > 0 || '1つは必須選択です。'
        }
      },
      selected: [],
      emailRules: {
        regex: v => /^(([^<>()[\]\\.,;:\s@]+(\.[^<>()[\]\\.,;:\s@]+)*)|(.+))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'メールアドレスの形式が正しくありません'
      },
      menu: false,
      courses: [{ label: "Cut大人", value: "Cut大人"}, { label: "Cut高校生", value: "Cut高校生"}, {label: "Cut中学生", value: "Cut中学生"}, {label: "Cut小学生", value: "Cut小学生"}, {label: "Color白髪ぼかし", value: "Color白髪ぼかし"},{ label: "グレイカラー", value: "グレイカラー"}, { label: "デザインカラー", value: "デザインカラー"}, {label: "Perm Basicパーマ", value: "Perm Basicパーマ"}, {label: "縮毛矯正", value: "縮毛矯正"}, {label: "Spa・FRESH", value: "Spa・FRESH"},{label: "Spa・SCALP", value: "Spa・SCALP"}]
    }
  },
  computed: {
    st_tm: {
      get: function() {
        return this.user.st_tm.format('YYYY年MM月DD日 HH:mm')
      }
    }
  },
  watch: {
    menu (val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
    },
  },
  methods: {
    submit() {
      if (this.$refs.send_data.validate()) {
        this.success = true
        this.$router.push('/confirm')
      } else {
        this.success = false
      }
    }
  }
}
</script>