<script>
import linelogo from "super-tiny-icons/images/svg/line.svg";
import LineCard from '@/components/LINECard'

export default {
  components: {
    LineCard,
  },
  props: {
    reserveData: Object
  },
  data() {
    return {
      targetDialog: null,
      linelogo: linelogo,
      dialog: false
    }
  },
  created() {
    this.scrollTop()
  },
  methods: {
    scrollTop() {
    },
    openLINE() {
      this.dialog = true
    },
    onLINEClose() {
      this.dialog = false
    },
    close() {
      if (this.targetDialog != null) this.targetDialog.scrollTop = 0
      this.$emit('clickClose')
    },
    handleScroll(e) {
      if (this.targetDialog == null) this.targetDialog = e.target
    }
  }  
}
</script>
<template>
  <v-card>
    <v-card-text id="scroll-target" v-scroll:#scroll-target="handleScroll">
      <v-card-text>
      <v-text-field v-model="reserveData.st_tm" label="予約日時" readonly />
      <v-text-field v-model="reserveData.course" label="メニュー" readonly />
      <v-text-field v-model="reserveData.name" label="お名前" readonly/>
      <v-text-field v-model="reserveData.phone" label="電話番号（固定電話・携帯番号）" readonly>
        <template v-slot:append>
          <v-btn icon :href="'tel:' + reserveData.phone"><v-icon>phone</v-icon></v-btn>
          <v-btn icon :href="'sms:' + reserveData.phone"><v-icon>sms</v-icon></v-btn>
          <v-btn icon><img width="24" height="24" :src=linelogo @click="openLINE"></v-btn>
        </template>
      </v-text-field>
      <v-textarea v-model="reserveData.note" label="その他・要望" outlined readonly/>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text  @click="close">
          閉じる
        </v-btn>
      </v-card-actions>
      </v-card-text>
    </v-card-text>
    <!-- dialog -->
    <div>
      <v-dialog persistent v-model="dialog" width="500px">
        <line-card
          v-on:clickClose="onLINEClose"
          :reserveData="reserveData"
        ></line-card>
      </v-dialog>
    </div>
  </v-card>
</template>