<template>
  <v-card width="650px" class="mx-auto mt-5">
    <v-container>
      <v-card-title>
        <h1 v-if="this.user.manage_id.length == 0" class="display-1">ご予約完了</h1>
        <h1 v-else class="display-1">ご予約変更完了</h1>
      </v-card-title>

      <v-card-text v-if="this.user.manage_id.length == 0">{{user.name}}様のご予約が完了しました</v-card-text>
      <v-card-text v-else>ご予約変更が完了しました</v-card-text>

      <v-row>
        <v-col v-if="this.user.isClient">
          <v-btn color="success" block class="font-weight-bold" v-on:click="closeWindows">閉じる</v-btn>
        </v-col>
        <v-col v-else>
          <v-btn color="success" block class="font-weight-bold" v-on:click="openTop">トップへ戻る</v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
const moment = require("moment");

export default {
  props: {
    user: Object
  },
  data() {
    return {
      doneFlg: true
    }
  },
  beforeRouteLeave (to, from, next) {
    if (this.doneFlg) {
      next(false)
    } else {
      next()
    }
  },
  created () {
    this.lineSendMessage()
  },
  methods: {
    openTop() {
      this.doneFlg = false
      const url = process.env.VUE_APP_TOP_URL
      location.replace(url)
    },
    closeWindows() {
      liff.closeWindow()
    },
    lineSendMessage() {
    var course = '';
    this.user.course.forEach(element => {
            if (course.length > 0) {
                course += ', ';
            }
            course += element;
        });
      if (this.user.manage_id.length == 0) {
        liff.sendMessages([
          {
            "type": "text",
            "text": "予約完了"
          },
          {
            "type": "flex",
            "altText": "ご予約完了",
            "contents": {
              "type": "bubble",
              "size": "giga",
              "body": {
                "type": "box",
                "layout": "vertical",
                "spacing": "md",
                "contents": [
                  {
                    "type": "text",
                    "text": "ご予約完了",
                    "size": "xl",
                    "weight": "bold",
                    "align": "center",
                    "color": "#1DB446"
                  },
                  {
                    "type": "box",
                    "layout": "vertical",
                    "spacing": "sm",
                    "contents": [
                      {
                        "type": "box",
                        "layout": "baseline",
                        "contents": [
                          {
                            "type": "text",
                            "text": "お名前:",
                            "size": "sm",
                            "flex": 0
                          },
                          {
                            "type": "text",
                            "text": `${this.user.name} 様`,
                            "align": "end",
                            "offsetTop": "none",
                            "size": "sm",
                            "flex": 8
                          }
                        ]
                      },
                      {
                        "type": "box",
                        "layout": "baseline",
                        "contents": [
                          {
                            "type": "text",
                            "text": "電話番号:",
                            "flex": 0,
                            "size": "sm"
                          },
                          {
                            "type": "text",
                            "text": this.user.phone,
                            "align": "end",
                            "size": "sm"
                          }
                        ]
                      },
                      {
                        "type": "separator",
                        "margin": "lg"
                      },
                      {
                          "type": "box",
                          "layout": "baseline",
                          "contents": [
                            {
                              "type": "text",
                              "text": "希望コース:",
                              "flex": 0,
                              "size": "sm"
                            },
                            {
                              "type": "text",
                              "text": course,
                              "align": "end",
                              "size": "sm"
                            }
                          ]
                      },
                      {
                        "type": "box",
                        "layout": "baseline",
                        "contents": [
                          {
                            "type": "text",
                            "text": "要望・その他:",
                            "flex": 0,
                            "size": "sm"
                          },
                          {
                            "type": "text",
                            "text": (this.user.note.length == 0)?" ":this.user.note,
                            "align": "end",
                            "size": "sm"
                          }
                        ]
                      },
                      {
                        "type": "separator",
                        "margin": "lg"
                      },
                      {
                        "type": "box",
                        "layout": "horizontal",
                        "contents": [
                          {
                            "type": "text",
                            "text": "ご予約日時:",
                            "size": "sm",
                            "flex": 0
                          },
                          {
                            "type": "text",
                            "text": moment(this.user.st_tm).format("YYYY年MM月DD HH:mm〜"),
                            "align": "end",
                            "size": "sm"
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            }
          }
        ])
        .then(() => {
        })
        .catch((err) => {
          console.log("line error="+err)
        })

      } else {
        liff.sendMessages([
          {
            "type": "text",
            "text": "予約変更"
          },
          {
            "type": "flex",
            "altText": "予約変更完了",
            "contents": {
              "type": "bubble",
              "size": "giga",
              "body": {
                "type": "box",
                "layout": "vertical",
                "spacing": "md",
                "contents": [
                  {
                    "type": "text",
                    "text": "予約変更完了",
                    "size": "xl",
                    "weight": "bold",
                    "align": "center",
                    "color": "#1DB446"
                  },
                  {
                    "type": "box",
                    "layout": "vertical",
                    "spacing": "sm",
                    "contents": [
                      {
                          "type": "box",
                          "layout": "baseline",
                          "contents": [
                            {
                              "type": "text",
                              "text": "希望コース:",
                              "flex": 0,
                              "size": "sm"
                            },
                            {
                              "type": "text",
                              "text": course,
                              "align": "end",
                              "size": "sm"
                            }
                          ]
                      },
                      {
                        "type": "separator",
                        "margin": "lg"
                      },
                      {
                        "type": "box",
                        "layout": "horizontal",
                        "contents": [
                          {
                            "type": "text",
                            "text": "ご予約日時:",
                            "size": "sm",
                            "flex": 0
                          },
                          {
                            "type": "text",
                            "text": moment(this.user.st_tm).format("YYYY年MM月DD HH:mm〜"),
                            "align": "end",
                            "size": "sm"
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            }
          }
        ])
        .then(() => {
        })
        .catch((err) => {
          console.log("line error="+err)
        })
      }

    }
  }

}
</script>

