<template>
  <v-card>
    <v-card-title>ご希望時間を選択してください</v-card-title>
    <v-card-text>選択日: {{targetDate}}</v-card-text>
    <v-card-text>
      <v-row>
      <div v-for="(category,index) in schedules" :key="schedules[index].value">
        <v-col><v-btn color="info" v-model="selected" @click="submit(category.value)">{{category.label}}</v-btn></v-col>
      </div>

      </v-row>

    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn text  @click="close">
        閉じる
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
const moment = require("moment")
moment.locale('ja')
export default {
  props: {
    calendarData: Object
  },
  computed: {
    targetDate: {
      get: function() {
        return this.calendarData.st_tm.format('YYYY年MM月DD日(ddd)')
      }
    },
    schedules: {
      get: function() {
        const tmList = []
        const ed_tm = this.calendarData.ed_tm
        var nowTM = moment()
        var targetTM = this.calendarData.st_tm
        while(targetTM.isBefore(ed_tm)) {
          if (targetTM.isAfter(nowTM.format('YYYY-MM-DD HH:mm'))) {
            const result = this.calendarData.ngList.find( event => (event.summary != process.env.VUE_APP_TEIKYU_WORD && (targetTM.isBetween(moment(event.start.dateTime), moment(event.end.dateTime)) || moment(event.start.dateTime).isSame(targetTM) ) ));
            if (!result) {
              tmList.push({
                "label": `${targetTM.format('HH:mm')}〜`, 
                "value": targetTM.format('YYYY-MM-DD HH:mm')
              });
            }
          }
          targetTM.add(process.env.VUE_APP_INTERVAL, 'm')
        }
        return tmList
/*        
        // const st = this.calendarData.st_tm.format('HH:mm')
        // const ddd = this.calendarData.st_tm.format('ddd')

        const tmList = [];
        var nowTM = moment();
        var targetTM = this.calendarData.st_tm

        if (targetTM.isAfter(nowTM.format('YYYY-MM-DD HH:mm'))) {
          tmList.push({
            "label": `${targetTM.format('HH:mm')}〜`, 
            "value": targetTM.format('YYYY-MM-DD HH:mm')
          });
        }

        // while(true) {
        //   if (targetTM.isAfter(nowTM.format('YYYY-MM-DD HH:mm'))) {
        //     tmList.push({
        //       "label": `${targetTM.format('HH:mm')}〜`, 
        //       "value": targetTM.format('YYYY-MM-DD HH:mm')
        //     });
        //   }
        //   if (st === '09:00') {
        //     if (targetTM.format('HH:mm') === '11:30') {
        //       break;
        //     } else {
        //       targetTM.add(30, 'm');
        //     }
        //   } else {
        //     if (targetTM.format('HH:mm') === ((ddd === '土')?'17:30':'18:30')) {
        //       break;
        //     } else {
        //       targetTM.add(30, 'm');
        //     }

        //   }
        // }

        return tmList;
*/
      }
    }
  },
  data() {
    return {
      selected: []
    }
  },
  methods: {
    close() {
      this.$emit('clickClose')
    },
    submit(select) {
      this.$emit('clickSubmit', {
        selected: select
      })
    }
  }
}
</script>