<template>
  <v-card width="650px">
    <v-card-text id="scroll-target" v-scroll:#scroll-target="handleScroll">
      <v-card-title>NG枠を設定してください</v-card-title>
      <v-card-text>選択日: {{targetDate}}</v-card-text>
      <v-row>
        <div v-for="(category,index) in schedules" :key="schedules[index].value">
          <v-col v-if="category.ngItem==null"><v-btn :color=category.color v-model="selected" @click="submit(category.value, category.ngItem)">{{category.label}}</v-btn></v-col>
          <v-col v-else><v-btn :disabled="(category.ngItem.id)?false:true" :color=category.color v-model="selected" @click="submit(category.value, category.ngItem)">{{category.label}}</v-btn></v-col>
        </div>
      </v-row>
      <v-card-text>
        <v-text-field v-if="isAllFlg != undefined" v-model="isAllFlg.description" label="公開用タイトル" outlined readonly />
        <v-text-field v-else v-model="openTitle" label="公開用タイトル" outlined />
        <v-btn :color="(isAllFlg != undefined)?'error':'success'" block @click="submitAll(isAllFlg)">
          {{(isAllFlg != undefined)?"終日❌":"終日⭕"}}
        </v-btn>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text  @click="close">
          閉じる
        </v-btn>
      </v-card-actions>
    </v-card-text>
  </v-card>
</template>

<script>
const moment = require("moment")
moment.locale('ja')
export default {
  props: {
    calendarData: Object
  },
  computed: {
    targetDate: {
      get: function() {
        return this.calendarData.st_tm.format('YYYY年MM月DD日(ddd)')
      }
    },
    isAllFlg: {
      get: function() {
        const resultTeikyu = this.calendarData.ngList.find( event => {
          return (event.summary === process.env.VUE_APP_TEIKYU_WORD && (this.calendarData.st_tm.isBetween(moment(event.start.date), moment(event.end.date)) || moment(event.start.date).isSame(this.calendarData.st_tm) ) )
        });
        return resultTeikyu
      }
    },
    schedules: {
      get: function() {
        const tmList = []

        const resultTeikyu = this.calendarData.ngList.find( event => {
          return (event.summary === process.env.VUE_APP_TEIKYU_WORD && (this.calendarData.st_tm.isBetween(moment(event.start.date), moment(event.end.date)) || moment(event.start.date).isSame(this.calendarData.st_tm) ) )
        });
        if (resultTeikyu) return tmList;
        
        const ed_tm = this.calendarData.ed_tm
        var nowTM = moment()
        var targetTM = this.calendarData.st_tm
        while(targetTM.isBefore(ed_tm)) {
          if (targetTM.isAfter(nowTM.format('YYYY-MM-DD HH:mm'))) {
            const result = this.calendarData.ngList.find( event => {
              return (event.summary != process.env.VUE_APP_TEIKYU_WORD && (targetTM.isBetween(moment(event.start.dateTime), moment(event.end.dateTime)) || moment(event.start.dateTime).isSame(targetTM) ) )
            });
            if (!result) {
              tmList.push({
                "label": `${targetTM.format('HH:mm')}　⭕`,
                "value": targetTM.format('YYYY-MM-DD HH:mm'),
                "color": "success",
                "ngItem": null
              });
            } else {
              if (result.regular == undefined) {
                // 予約NG枠
                //IDある==Googleカレンダーで設定したもの
                tmList.push({
                  "label": `${targetTM.format('HH:mm')}　❌`, 
                  "value": targetTM.format('YYYY-MM-DD HH:mm'),
                  "color": "error",
                  "ngItem": result
                });
              }
            }
          }
          targetTM.add(process.env.VUE_APP_INTERVAL, 'm')
        }
        return tmList
      }
    }
  },
  data() {
    return {
      selected: [],
      openTitle: '',
      targetDialog: null
    }
  },
  methods: {
    close() {
      if (this.targetDialog != null) this.targetDialog.scrollTop = 0
      this.$emit('clickClose')
    },
    submit(select, ngItem) {
      this.$emit('clickSubmit', {
        selected: select,
        ngItem: ngItem
      })
    },
    submitAll(isAllFlg) {
      this.$emit('clickSubmitAll', {
        selected: this.calendarData.st_tm,
        openTitle: this.openTitle,
        isAllFlg: isAllFlg
      })
    },
    handleScroll(e) {
      if (this.targetDialog == null) this.targetDialog = e.target
    }
  }
}
</script>