import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify';
import firebase from 'firebase/app'
// import VConsole from 'vconsole'

Vue.config.productionTip = false

var firebaseConfig = {
  apiKey: "AIzaSyCIigNZoNaRb2FDwvpt0X5aR67l0-7v5-A",
  authDomain: "treat-maiself.firebaseapp.com",
  projectId: "treat-maiself",
  storageBucket: "treat-maiself.appspot.com",
  messagingSenderId: "1092788710432",
  appId: "1:1092788710432:web:af4d9cdee7c37f3d4fa045"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

// vconsole
// new VConsole()

new Vue({
  router,
  vuetify,
  render: function (h) { return h(App) }
}).$mount('#app')
